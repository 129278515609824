let timelineElements = [
  {
    id: 1,
    title: "Honors in Business Administration - HBA",
    location: "Ivey Business School",
    description: "• Scholarship of Excellence",
    buttonText: "View Backend Projects",
    date: "Sept. 2020 - Apr. 2024",
    icon: "work",
  },
  {
    id: 3,
    title: "Bachelor of Engineering Science - Software",
    buttonText: "Western University",
    location: "Western University",
    description:
      "•  MacKay-Lassonde Award \n • Vladmir Stritesky Engineering Award",
    date: "Sept. 2018 - Apr. 2024",
    icon: "work",
  },
];

export default timelineElements;
