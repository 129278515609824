import React from "react";
import "../sass/about.scss";
const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section
      id="about"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-25 fw-600 w-100 mb-0 allura-font " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-9")
            }
          >
            About Me
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Know Me More
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>

        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="profile-picture">
            <img
              src={process.env.PUBLIC_URL + "/images/profile.jpg"}
              alt="Profile"
              className="profile-image"
            />
          </div>

          <div className="text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              I'm <span className="text-primary">Areej Ansari</span> a Software
              and Business Student
            </h2>
            <p
              style={{ textAlign: "justify" }}
              className={darkTheme ? "text-white-50" : ""}
            >
              As a student finishing my final year of a dual degree program in
              Software Engineering and Business Administration, I am driven by a
              deep passion for technology and its integration with various
              aspects of the business world. Throughout my academic journey, I
              have developed a strong foundation in both technical and
              managerial skills, allowing me to take a holistic approach to
              problem-solving.
            </p>
            <p
              style={{ textAlign: "justify" }}
              className={darkTheme ? "text-white-50" : ""}
            >
              Beyond academia, I find immense joy in gaming and streaming my
              gaming adventures on Twitch. As a former member of the Corporate
              Esports Association, I competed in Valorant tournaments for IBM,
              raising funds for charitable causes and I am currently a member of
              Western Wings (the first women's Valorant team at Western
              University). When I'm not competing, I enjoy immersing myself in
              campaign story games that captivate my imagination. My passion for
              tech extends beyond gaming as I love to stay up-to-date with the
              latest innovations be it consoles, AI applications or even
              lighting systems.
            </p>
            <p
              style={{ textAlign: "justify" }}
              className={darkTheme ? "text-white-50" : ""}
            >
              In an era where social media is prevalent, I dabble in photography
              and content creation on my social media platforms, documenting my
              experiences as a student and intern. By leveraging the reach and
              influence of social media, I recognize the potential to positively
              impact others' lives, offering a relatable and authentic
              perspective on the challenges and triumphs of navigating the adult
              world. If you're interested in getting to know me more or would
              simply like to have a conversation feel free to contact me!
            </p>
          </div>

          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}

        <div className="brands-grid separator-border mt-4">
          <div className="row justify-content-center">
            <div className="col-2">
              {/* Instagram icon box */}
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <a
                    href="https://www.instagram.com/riji.ansari/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="images/instagram-icon.png"
                      alt="Instagram Icon"
                      width="60"
                      height="60"
                    />
                  </a>
                </h4>
                <a
                  href="https://www.instagram.com/riji.ansari/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="social-text" style={{ color: "#252b33" }}>
                    Instagram
                  </p>
                </a>
              </div>
            </div>
            <div className="col-2">
              {/* Twitch icon box */}
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <a
                    href="https://www.twitch.tv/rijispov"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="images/twitch-icon.png"
                      alt="Twitch Icon"
                      width="60"
                      height="60"
                    />
                  </a>
                </h4>
                <a
                  href="https://www.twitch.tv/rijispov"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="social-text" style={{ color: "#252b33" }}>
                    Twitch
                  </p>
                </a>
              </div>
            </div>
            <div className="col-2">
              {/* Email icon box */}
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <a
                    href="mailto:areej.ansari@uwo.ca"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="images/mail-icon.png"
                      alt="Email Icon"
                      width="60"
                      height="60"
                    />
                  </a>
                </h4>
                <a
                  href="https://tiktok.com/@riji_ansari"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="social-text" style={{ color: "#252b33" }}>
                    Email
                  </p>
                </a>
              </div>
            </div>
            <div className="col-2">
              {/* TikTok icon box */}

              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <a
                    href="https://tiktok.com/@riji_ansari"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="images/tiktok-icon.png"
                      alt="TikTok Icon"
                      width="60"
                      height="60"
                    />
                  </a>
                </h4>
                <a
                  href="https://tiktok.com/@riji_ansari"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="social-text" style={{ color: "#252b33" }}>
                    TikTok
                  </p>
                </a>
              </div>
            </div>
            <div className="col-2">
              {/* Twitter icon box */}
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <a
                    href="https://twitter.com/riji_ansari"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="images/twitter-icon.png"
                      alt="Twitter Icon"
                      width="60"
                      height="60"
                    />
                  </a>
                </h4>
                {/*Look into changing the colour from green to something else*/}
                <a
                  href="https://twitter.com/riji_ansari"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="social-text" style={{ color: "#252b33" }}>
                    Twitter
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
