import React, { useEffect } from "react";
import "../sass/resume.scss";
import timelineElements from "./timelineElements";
import experienceElements from "./experienceElements";
import leadershipElements from "./leadershipElements";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";

const skillsData = [
  { name: "HTML", proficiency: 80 },
  { name: "CSS", proficiency: 80 },
  { name: "JavaScript", proficiency: 95 },
  { name: "React", proficiency: 95 },
  { name: "XML", proficiency: 95 },
  { name: "MySQL", proficiency: 95 },
  { name: "Python", proficiency: 95 },
  { name: "Java", proficiency: 95 },
  { name: "C#", proficiency: 95 },
  { name: "Unity", proficiency: 95 },
];

const designData = [
  { name: "Lightroom", proficiency: 95 },
  { name: "Photoshop", proficiency: 95 },
  { name: "Premiere", proficiency: 95 },
  { name: "Figma", proficiency: 95 },
  { name: "UX/UI", proficiency: 95 },
  { name: "Photography", proficiency: 95 },

  // Add more skills as needed
];

const Resume = ({ classicHeader, darkTheme }) => {
  // useEffect(() => {
  //   const skillBubbles = document.querySelectorAll(".skill");

  //   const moveBubble = (e, bubble) => {
  //     const bubbleRect = bubble.getBoundingClientRect();
  //     const bubbleX = bubbleRect.left + bubbleRect.width / 2;
  //     const bubbleY = bubbleRect.top + bubbleRect.height / 2;
  //     const distanceX = bubbleX - e.clientX;
  //     const distanceY = bubbleY - e.clientY;
  //     const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);
  //     const maxDistance = 100;
  //     if (distance < maxDistance) {
  //       const moveDistance = maxDistance - distance;
  //       const moveX = (distanceX / distance) * moveDistance;
  //       const moveY = (distanceY / distance) * moveDistance;
  //       bubble.style.transform = `translate(${moveX}px, ${moveY}px)`;
  //     } else {
  //       bubble.style.transform = "";
  //     }
  //   };

  //   const handleMouseMove = (e) => {
  //     skillBubbles.forEach((bubble) => {
  //       moveBubble(e, bubble);
  //     });
  //   };

  //   document.addEventListener("mousemove", handleMouseMove);

  //   return () => {
  //     document.removeEventListener("mousemove", handleMouseMove);
  //   };
  // }, []);

  return (
    <section id="resume" className="section-resume">
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-25 fw-600 w-100 mb-0 allura-font " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-9")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-10 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
      </div>
      <div className="row gx-5">
        {/* My Education */}
        <div className="col-md-6">
          <div className="timeline-section education">
            <h2 className="title">Education</h2>
            {/* Education timeline elements here */}

            <VerticalTimeline
              className="my-timeline-container"
              layout="1-column-left"
            >
              {timelineElements.map((element) => {
                return (
                  <VerticalTimelineElement key={element.key}>
                    <span className="timeline-date">{element.date}</span>

                    <h4 className="vertical-timeline-element-title">
                      <strong>{element.title}</strong>
                    </h4>
                    <h5 className="vertical-timeline-element-subtitle">
                      {element.location}
                    </h5>

                    <p id="description">
                      {element.description
                        .split("\n")
                        .map((line, index, array) => (
                          <React.Fragment key={index}>
                            {line}
                            {index !== array.length - 1 && <br />}
                          </React.Fragment>
                        ))}
                    </p>
                  </VerticalTimelineElement>
                );
              })}
            </VerticalTimeline>
          </div>

          <div className="timeline-section leadership">
            <h2 className="title">Leadership</h2>

            <VerticalTimeline
              className="my-timeline-container"
              layout="1-column-left"
            >
              {leadershipElements.map((element) => {
                return (
                  <VerticalTimelineElement key={leadershipElements.key}>
                    <span className="timeline-date">{element.date}</span>

                    <h4 className="vertical-timeline-element-title">
                      <strong>{element.title}</strong>
                    </h4>
                    <h5 className="vertical-timeline-element-subtitle">
                      {element.location}
                    </h5>

                    <p id="description">
                      {element.description
                        .split("\n")
                        .map((line, index, array) => (
                          <React.Fragment key={index}>
                            {line}
                            {index !== array.length - 1 && <br />}
                          </React.Fragment>
                        ))}
                    </p>
                  </VerticalTimelineElement>
                );
              })}
            </VerticalTimeline>
          </div>
        </div>

        <div className="col-md-6">
          <h2 className="title">Experience</h2>
          <VerticalTimeline
            className="my-timeline-container"
            layout="1-column-left"
          >
            {experienceElements.map((element) => {
              return (
                <VerticalTimelineElement key={element.key}>
                  <span className="timeline-date">{element.date}</span>

                  <h4 className="vertical-timeline-element-title">
                    <strong>{element.title}</strong>
                  </h4>
                  <h5 className="vertical-timeline-element-subtitle">
                    {element.location}
                  </h5>

                  <p id="description">
                    {element.description
                      .split("\n")
                      .map((line, index, array) => (
                        <React.Fragment key={index}>
                          {line}
                          {index !== array.length - 1 && <br />}
                        </React.Fragment>
                      ))}
                  </p>
                </VerticalTimelineElement>
              );
            })}
          </VerticalTimeline>
          <div className="timelineEnd"></div>
        </div>
      </div>

      <div className="skills-certifications">
        <h2>Business Leadership</h2>
        <div class="certificates-container">
          <a
            href="https://www.ivey.uwo.ca/hba/courses/leading-people-and-organizations/"
            target="_blank"
            class="certificate-card"
          >
            <div class="card-content">
              <h4>Leading People and Organizations</h4>
            </div>
          </a>
          <a
            href="https://www.ivey.uwo.ca/hba/courses/global-macroeconomics-for-managers/"
            target="_blank"
            class="certificate-card"
          >
            <div class="card-content">
              <h4>Global Macroeconomics for Managers</h4>
            </div>
          </a>
          <a
            href="https://www.ivey.uwo.ca/hba/courses/decision-making-with-analytics/"
            target="_blank"
            class="certificate-card"
          >
            <div class="card-content">
              <h4>Decision Making with Analytics</h4>
            </div>
          </a>
          <a
            href="https://www.ivey.uwo.ca/hba/courses/operations/"
            target="_blank"
            class="certificate-card"
          >
            <div class="card-content">
              <h4>Operations</h4>
            </div>
          </a>
          <a
            href="../images/4621 Course Outline.pdf"
            target="_blank"
            class="certificate-card"
          >
            <div class="card-content">
              <h4>Managing in the Creative Economy</h4>
            </div>
          </a>
          <a
            href="https://www.ivey.uwo.ca/hba/courses/strategy/"
            target="_blank"
            class="certificate-card"
          >
            <div class="card-content">
              <h4>Strategy</h4>
            </div>
          </a>
          <a
            href="https://www.ivey.uwo.ca/hba/courses/marketing/"
            target="_blank"
            class="certificate-card"
          >
            <div class="card-content">
              <h4>Marketing</h4>
            </div>
          </a>
          <a
            href="https://www.ivey.uwo.ca/hba/courses/project-management/"
            target="_blank"
            class="certificate-card"
          >
            <div class="card-content">
              <h4>Project Management</h4>
            </div>
          </a>
          <a
            href="https://www.ivey.uwo.ca/hba/courses/data-management-for-decision-making/"
            target="_blank"
            class="certificate-card"
          >
            <div class="card-content">
              <h4>Data Management</h4>
            </div>
          </a>
        </div>
        <h2>Skills and Certifications</h2>
        <div class="certificates-container">
          <a
            href="https://courses.cognitiveclass.ai/certificates/649c12c273f640d3b51f9a191c57f544"
            target="_blank"
            class="certificate-card"
          >
            <div class="card-content">
              <h4>Machine Learning with Python</h4>
            </div>
          </a>
          <a
            href="https://courses.cognitiveclass.ai/certificates/c341e86f81eb49fc9837e9d96b1b89f4"
            target="_blank"
            class="certificate-card"
          >
            <div class="card-content">
              <h4>Introduction to Cloud</h4>
            </div>
          </a>
          <a
            href="https://courses.cognitiveclass.ai/certificates/afe94ade7f3d4725b29aedfa56308a22"
            target="_blank"
            class="certificate-card"
          >
            <div class="card-content">
              <h4>Data Science 101</h4>
            </div>
          </a>
          <a
            href="https://courses.cognitiveclass.ai/certificates/c2d693d444d24a65b531ef6fc1fa6e74"
            target="_blank"
            class="certificate-card"
          >
            <div class="card-content">
              <h4>Deep Learning Fundamentals</h4>
            </div>
          </a>
          <a
            href="https://courses.cognitiveclass.ai/certificates/3cdc01346cfc48888f2319753938a486"
            target="_blank"
            class="certificate-card"
          >
            <div class="card-content">
              <h4>Data Privacy Fundamentals</h4>
            </div>
          </a>
        </div>
        <div className="skill-card"></div>
        <div className="skill-cloud">
          {skillsData.map((skill, index) => (
            <div
              key={index}
              className="skill"
              style={{
                fontSize: `${Math.max(12, skill.proficiency / 10)}px`,
                // You can adjust the size, color, etc., based on proficiency
              }}
            >
              {skill.name}
            </div>
          ))}
          {designData.map((skill, index) => (
            <div
              key={index}
              className="skill"
              style={{
                fontSize: `${Math.max(12, skill.proficiency / 10)}px`,
                // You can adjust the size, color, etc., based on proficiency
              }}
            >
              {skill.name}
            </div>
          ))}
        </div>
        <div className="pdfButton">
          <a
            href="../images/Ansari-Resume.pdf"
            download
            className="btn btn-primary rounded-pill"
          >
            Download Resume
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
