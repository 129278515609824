let timelineElements = [
  {
    id: 1,
    title: "Software Representative",
    location: "Western Undergraduate Engineering Society",
    description:
      "• Managed over $500,000 worth of capital and disbursements therefore providing new equipment to over 100 students.",
    date: "Apr. 2019 - Apr. 2022",
  },
  {
    id: 2,
    title: "Vice President of Finance",
    location: "Western Muslim Students' Association",
    description:
      "• Developed a financial plan to acquire sponsors and received over $15,000 in sponsorships for various events.",
    date: "June 2018 - Apr. 2022",
  },
];

export default timelineElements;
