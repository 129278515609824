let timelineElements = [
  {
    id: 1,
    title: "Project Manager",
    location: "RBC - Software Capstone",
    description:
      "• Led the Climate Impact on Equity Sales project, focusing on the front-end development of the system.  \n • Employed Agile methodologies to manage project timelines and authored project proposals for the Capstone.",
    buttonText: "View Frontend Projects",
    date: "Sept. 2023 - Apr. 2024",
    icon: "work",
  },
  {
    id: 2,
    title: "Software & UX Intern",
    location: "IBM",
    description:
      "• Managed a JavaScript program that updates Airtable data to support product releases for the content design team. \n• Streamlined the API migration by consolidating API information into a unified platform for clients and developers.",
    buttonText: "View Backend Projects",
    date: "May 2022 - Aug. 2023",
    icon: "work",
  },
  {
    id: 4,
    title: "Software Consultant",
    location: "Mattamy Homes",
    description:
      "• Provided a thorough recommendation plan to implement Building Information Modelling (BIM) technology internally. \n• Created a long-term plan highlighting which data to collect and how to improve the BIM process using these metrics.",
    buttonText: "Course Certificate",
    date: "Sept. 2021 - Jan. 2022",
    icon: "school",
  },

  {
    id: 3,
    title: "Web & Marketing Intern",
    location: "Western University | Ivey Business School",
    description:
      "• Developed Western’s Equity, Diversity and Inclusion website using Western's content management system: Cascade. \n• Developed a Java program which updated the graphics code for every page in each Faculty and optimized the number of conversions from less than 50 over six weeks to over 200 web pages within three weeks.",
    buttonText: "Course Certificate",
    date: "May 2019 - Apr. 2022",
    icon: "school",
  },
];

export default timelineElements;
