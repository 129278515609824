("use client");
import { ImageGallery } from "react-image-grid-gallery";
import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import "../sass/portfolio.scss";
import ProjectDetailsModal from "./ProjectDetailsModal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Portfolio = ({ classicHeader, darkTheme }) => {
  const filters = {
    DESIGN: "Design",
    SOFTWARE: "Projects",
    PHOTOGRAPHY: "Photography",
  };
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState(filters.DESIGN);
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const pdfImages = [
    {
      src: "../images/pdf-portfolio/png/1.png",
      isClickable: false,
      linkUrl: "",
    },
    {
      src: "../images/pdf-portfolio/png/2.png",
      isClickable: false,
      linkUrl: "",
    },
    {
      src: "../images/pdf-portfolio/png/3.png",
      isClickable: false,
      linkUrl: "",
    },
    {
      src: "../images/pdf-portfolio/png/4.png",
      isClickable: false,
      linkUrl: "",
    },
    {
      src: "../images/pdf-portfolio/png/5.png",
      isClickable: false,
      linkUrl: "",
    },
    {
      src: "../images/pdf-portfolio/png/6.png",
      isClickable: false,
      linkUrl: "",
    },
    {
      src: "../images/pdf-portfolio/png/7.png",
      isClickable: false,
      linkUrl: "",
    },
    {
      src: "../images/pdf-portfolio/png/8.png",
      isClickable: false,
      linkUrl: "",
    },
    {
      src: "../images/pdf-portfolio/png/9.png",
      isClickable: false,
      linkUrl: "",
    },
    {
      src: "../images/pdf-portfolio/png/10.png",
      isClickable: false,
      linkUrl: "",
    },
    {
      src: "../images/pdf-portfolio/png/11.png",
      isClickable: true,
      linkUrl: "https://riji-ansari.ca/",
    },
    {
      src: "../images/pdf-portfolio/png/12.png",
      isClickable: true,
      linkUrl: "https://www.mcuwo.ca/",
    },
    {
      src: "../images/pdf-portfolio/png/13.png",
      isClickable: false,
      linkUrl: "",
    },
    {
      src: "../images/pdf-portfolio/png/14.png",
      isClickable: false,
      linkUrl: "",
    },
    {
      src: "../images/pdf-portfolio/png/15.png",
      isClickable: true,
      linkUrl: "https://devpost.com/software/val-t",
    },
    {
      src: "../images/pdf-portfolio/png/16.png",
      isClickable: true,
      linkUrl:
        "https://www.youtube.com/watch?v=9AMACmnW8Bo&ab_channel=AreejAnsari",
    },
    {
      src: "../images/pdf-portfolio/png/17.png",
      isClickable: true,
      linkUrl: "https://devpost.com/software/jet-lag-fixer",
    },
    {
      src: "../images/pdf-portfolio/png/18.png",
      isClickable: false,
      linkUrl: "",
    },
    {
      src: "../images/pdf-portfolio/png/19.png",
      isClickable: false,
      linkUrl: "",
    },
    {
      src: "../images/pdf-portfolio/png/20.png",
      isClickable: false,
      linkUrl: "",
    },
    {
      src: "../images/pdf-portfolio/png/21.png",
      isClickable: false,
      linkUrl: "",
    },
    {
      src: "../images/pdf-portfolio/png/22.png",
      isClickable: true,
      linkUrl: "https://linktr.ee/riji.ansari",
    },
  ];

  const imagesArray = [
    {
      alt: "Toronto's Cherry Blossom",
      caption: "Toronto's Cherry Blossom",
      src: "../images/portfolio-images/24.jpg",
    },
    {
      alt: "Cat Sunbathing",
      caption: "Cat Sunbathing",
      src: "../images/portfolio-images/18.jpg",
    },
    {
      alt: "Lake Louise",
      caption: "Lake Louise",
      src: "../images/portfolio-images/2.jpg",
    },

    {
      alt: "Cactus",
      caption: "Cactus",
      src: "../images/portfolio-images/5.jpg",
    },

    {
      alt: "Wild Flowers",
      caption: "Wild Flowers",
      src: "../images/portfolio-images/7.jpg",
    },
    {
      alt: "Pink Flower",
      caption: "Pink Flower",
      src: "../images/portfolio-images/8.jpg",
    },

    {
      alt: "Tabby Cat",
      caption: "Tabby Cat",
      src: "../images/portfolio-images/6.jpg",
    },
    {
      alt: "Western University in Summer",
      caption: "Western University in Summer",
      src: "../images/portfolio-images/9.jpg",
    },
    {
      alt: "Cherry Blossom Branch",
      caption: "Cherry Blossom Branch",
      src: "../images/portfolio-images/25.jpg",
    },
    {
      alt: "Chicago Building",
      caption: "Chicago Building",
      src: "../images/portfolio-images/30.jpg",
    },

    {
      alt: "Western University in Fall",
      caption: "Western University in Fall",
      src: "../images/portfolio-images/10.jpg",
    },
    {
      alt: "Banff Mountain",
      caption: "Banff Mountain",
      src: "../images/portfolio-images/1.jpg",
    },

    {
      alt: "Istanbul Mosque Mimbar",
      caption: "Istanbul Mosque Mimbar",
      src: "../images/portfolio-images/16.jpg",
    },
    {
      alt: "Istanbul Mosque Ceiling",
      caption: "Istanbul Mosque Ceiling",
      src: "../images/portfolio-images/17.jpg",
    },
    {
      alt: "Chicago Motorola Building",
      caption: "Chicago Motorola Building",
      src: "../images/portfolio-images/4.jpg",
    },
    {
      alt: "Istanbul Mosque Exterior",
      caption: "Istanbul Mosque Exterior",
      src: "../images/portfolio-images/14.jpg",
    },

    {
      alt: "Lake Louise Cottage",
      caption: "Lake Louise Cottage",
      src: "../images/portfolio-images/3.jpg",
    },
    {
      alt: "Istanbul Guards",
      caption: "Istanbul Guards",
      src: "../images/portfolio-images/19.jpg",
    },
    {
      alt: "Hagia Sophia",
      caption: "Hagia Sophia Interior",
      src: "../images/portfolio-images/20.jpg",
    },
    {
      alt: "Sultan Ahmet Rose",
      caption: "Sultan Ahmet Rose",
      src: "../images/portfolio-images/21.jpg",
    },
    {
      alt: "Jasper Roadtrip",
      caption: "Jasper Roadtrip",
      src: "../images/portfolio-images/34.jpg",
    },
    {
      alt: "Bursa Gondola",
      caption: "Bursa Gondola",
      src: "../images/portfolio-images/22.jpg",
    },

    {
      alt: "Cherry Blossom Tree",
      caption: "Cherry Blossom Tree",
      src: "../images/portfolio-images/23.jpg",
    },
    {
      alt: "Chicago Nightlife",
      caption: "Chicago Nightlife",
      src: "../images/portfolio-images/32.jpg",
    },
    {
      alt: "Banff Railway",
      caption: "Banff Railway",
      src: "../images/portfolio-images/28.jpg",
    },
    {
      alt: "Chicago Roads",
      caption: "Chicago Roads",
      src: "../images/portfolio-images/29.jpg",
    },

    {
      alt: "Middlesex College in Fall",
      caption: "Middlesex College in Fall",
      src: "../images/portfolio-images/11.jpg",
    },
    {
      alt: "Istanbul Mosque",
      caption: "Istanbul Mosque",
      src: "../images/portfolio-images/15.jpg",
    },
    {
      alt: "Chicago Building Exterior",
      caption: "Chicago Building Exterior",
      src: "../images/portfolio-images/31.jpg",
    },

    {
      alt: "Jasper Snowfall",
      caption: "Jasper Snowfall",
      src: "../images/portfolio-images/33.jpg",
    },
    {
      alt: "University College in Fall",
      caption: "University College in Fall",
      src: "../images/portfolio-images/12.jpg",
    },
  ];
  // Slider settings for the Design section
  const sliderSettings = {
    dots: true, // Show dot indicators
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  // Custom arrow components for the slider
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }
  //Algorhythm Application
  const projectsData = [
    {
      title: "Algorhythm",
      projectInfo:
        "AlgoRhythm stands as an educational tool blending technology with creativity, designed to simplify the understanding of the merge sort algorithm through a game-based approach. My involvement centered on the design aspects, contributing to a user-friendly interface that enhances learning engagement. The application harnesses the power of React.js for its reactive front end, Node.js for a robust server-side, and integrates Firebase and GCP cloud services for seamless data handling and scalability.",
      client: "SE3350 - Software Engineering Design I",
      technologies: "React.js, Node.js, Firebase, GCP Cloud",
      industry: "Software & Education",
      github: {
        name: "AlgoRhythm Repo",
        link: "https://github.com/riji-ansari/AlgoRhythm",
      },
      url: {
        name: "AlgoRhythm Demo",
        link: "https://www.youtube.com/watch?v=9AMACmnW8Bo&ab_channel=AreejAnsari",
      },
      thumbImage: "images/projects/algorhythm.png",
      sliderImages: [
        "images/projects/intro-level.png",
        "images/projects/level-1.png",
      ],
      categories: [filters.SOFTWARE],
    },
    {
      title: "Cyberpunk Inferno",
      projectInfo:
        "Cyberpunk Inferno is 2D dungeon crawler RPG based on Dante's Divine Comedy. Players will navigate through 5 levels of Hell, encountering enemies and bosses. With a cyberpunk theme, the game offers unique twists on the classic tale and allows players to acquire stronger weapons, armors, relics, and skills along the way.",
      client: "SE2250 - Software Construction",
      technologies: "C#, C++, Unity, Git",
      industry: "Gaming",
      github: {
        name: "Cyberpunk Inferno Repo",
        link: "https://github.com/riji-ansari/Cyberpunk-Inferno",
      },
      url: {
        name: "Cyberpunk Inferno Demo",
        link: "https://www.youtube.com/watch?v=xexafzJNh0k&ab_channel=AreejAnsari",
      },

      thumbImage: "images/projects/cyberpunk.png",
      sliderImages: [
        "images/projects/cyberpunk-inferno1.png",
        "images/projects/cyberpunk-inferno2.png",
        "images/projects/cyberpunk-inferno3.png",
      ],
      categories: [filters.SOFTWARE],
    },
    {
      title: "RiSync",
      projectInfo:
        "At Hack Western 8, we developed RiSync, an innovative solution tailored to mitigate jet lag for global travelers. My contribution to the project was pivotal in the design phase, where I utilized Figma to create the web application's interface. The design facilitates a user-centric experience, guiding travelers to adjust their circadian rhythm through personalized sunlight exposure and sleep schedules. Built with React.js, our application is hosted on Google Cloud's Compute Engine, integrating the Google Places and Timezone APIs to ensure precise time zone synchronization.",
      client: "Hack Western 8",
      technologies: "React.js, JavaScript Library, Google APIs",
      industry: "Travel",
      github: {
        name: "RiSync Repo",
        link: "https://github.com/riji-ansari/RiSync",
      },
      url: {
        name: "RiSync Demo",
        link: "https://devpost.com/software/jet-lag-fixer",
      },

      thumbImage: "images/projects/risync.png",
      sliderImages: [
        "images/projects/risync-signup.png",
        "images/projects/risync-input-info.png",
      ],
      categories: [filters.SOFTWARE],
    },
    {
      title: "Val-T",
      projectInfo:
        "Val-T is a platform for online Valorant tournaments, collecting match data from around the world. It offers tailored features for players, spectators, and sponsors, including leaderboards, statistics, and team rankings for an immersive experience.",
      client: "SE3309 - Database Management Systems",
      technologies: "React.js, Express.js, MySQL",
      industry: "Software & Gaming",
      github: {
        name: "Val-T Repo",
        link: "https://github.com/riji-ansari/val-t",
      },
      url: {
        name: "Val-T Demo",
        link: "https://devpost.com/software/val-t",
      },

      thumbImage: "images/projects/val-t-homepage.png",
      sliderImages: [
        "images/projects/val-t-login.png",
        "images/projects/val-t-tournament.png",
      ],
      categories: [filters.SOFTWARE],
    },

    {
      title: "Rock Paper Scissors",
      projectInfo:
        "The project ties together elements used to create a multi-user, multi-transaction server through the game of Rock, Paper, Scissors.",
      client: "SE3313 - Operating Systems",
      technologies: "Java, VM (Linux), C++",
      industry: "Education",
      github: {
        name: "Rock Paper Scissors Repo",
        link: "https://github.com/riji-ansari/Rock-Paper-Scissors",
      },
      // url:
      // {
      //   name: "-"
      // },
      thumbImage: "images/projects/rps.png",
      categories: [filters.SOFTWARE],
    },

    {
      title: "Snakes & Ladders",
      projectInfo:
        "This project is a Java-based implementation of the classic snakes and ladder game with a graphical user interface (GUI). It allows two players to engage in a virtual board game experience where they navigate through a grid of numbered squares, encountering snakes that send them backwards and ladders that help them progress forward. The GUI enhances the user experience by providing visual feedback, interactive dice rolling, and an intuitive interface for seamless gameplay between the two players.",
      client: "Personal Project",
      technologies: "Java",
      industry: "Gaming",
      github: {
        name: "Snakes & Ladders GitHub Repo",
        link: "https://github.com/riji-ansari/SnakesAndLadders",
      },

      thumbImage: "images/projects/snakes-ladder.png",
      categories: [filters.SOFTWARE],
    },
    {
      title: "Personal Portfolio",
      projectInfo:
        "In the creation of my personal portfolio website, I sought to capture the essence of my professional identity through a bespoke design that speaks to both my expertise and personality. Built using React, this website serves as a living canvas that showcases my proficiency in web development and my eye for aesthetic detail. The palette of soft pinks and the use of cursive fonts are a nod to my personal style, providing a welcoming and elegant user experience. ",
      client: "Personal Project",
      technologies: "React.js, Firebase, HTML, CSS",
      github: {
        name: "Website Repo",
        link: "https://github.com/riji-ansari/resume",
      },
      url: {
        name: "Website Link",
        link: "https://riji-ansari.ca/",
      },

      thumbImage: "images/projects/portfolio.png",
      // sliderImages: [
      //   "images/projects/val-t-login.png",
      //   "images/projects/val-t-tournament.png",
      // ],
      categories: [filters.SOFTWARE],
    },
    {
      title: "RBC Climate Equity Sales",
      projectInfo:
        "In my capstone software project, I partnered with RBC to create a sophisticated Climate Equity Sales Prediction system. My role involved designing and developing the front-end interface of a platform that provides predictive insights into stock and sales fluctuations driven by weather patterns. This tool was engineered to assist financial analysts and investors in making data-informed decisions by correlating climate variables with market trends. The design is intuitive, ensuring users can seamlessly navigate through complex data, making the science of predictive analytics accessible and actionable for RBC's clientele.",
      client: "RBC",
      technologies: "React.js, Express.js, Python, CSS",
      github: {
        name: "RBC Capstone Repo",
        link: "https://github.com/rbc-weather-financial-forecast-capstone/Capstone2024RBC",
      },
      // url: {
      //   name: "Website Link",
      //   link: "https://devpost.com/software/val-t",
      // },

      thumbImage: "images/projects/rbc.png",
      // sliderImages: [
      //   "images/projects/val-t-login.png",
      //   "images/projects/val-t-tournament.png",
      // ],
      categories: [filters.SOFTWARE],
    },
    // {
    //   title: "Western Wings Website",
    //   projectInfo:
    //     "I'm currently in the process of developing the official website for Western Wings, the pioneering women's Valorant team at Western University. Tasked with both design and development, my goal  to encapsulate the team's dynamic spirit and competitive tenacity. The site acts as a hub for community connection, detailed game statistics, and the latest updates, celebrating the team’s journey in Esports. It will serve as a resource for potential sponsors and partnerships but also stands as a testament to the team’s commitment and impact in the collegiate gaming scene.",
    //   client: "Western Wings",
    //   technologies: "Figma, HTML, CSS, JavaScript, Project Management",
    //   // url: {
    //   //   name: "Muslim Chaplaincy Website",
    //   //   link: "https://www.mcuwo.ca/",
    //   // },
    //   thumbImage: "images/projects/western-wings.png",
    //   // sliderImages: ["images/projects/MCSWO.png"],
    //   categories: [filters.SOFTWARE],
    // },
    {
      title: "The Muslim Chaplaincy of Southwestern Ontario",
      projectInfo:
        "In a significant move to support its diverse student body, Western University re-established its Muslim Chaplaincy after nearly a decade. To accompany this milestone, I was entrusted with the honor of designing the Chaplaincy's web presence. My goal was to create a digital space that not only reflected the Chaplaincy's mission of providing support and guidance but also a welcoming platform that resonated with the values and cultural richness of the Muslim community. ",
      client: "London Muslim Chaplaincy",
      technologies: "Figma, WebFlow, HTML, CSS, JavaScript, Project Management",
      industry: "Community & Education",
      url: {
        name: "Muslim Chaplaincy Website",
        link: "https://www.mcuwo.ca/",
      },
      thumbImage: "images/projects/MCUWO.png",
      // sliderImages: ["images/projects/MCSWO.png"],
      categories: [filters.SOFTWARE],
    },
    {
      title: "Perfume Recommendation with BERT",
      projectInfo:
        "This project focuses on designing a perfume recommendation system for a fragrance retailer. The goal is to create a system that suggests five perfumes to a customer based on the similarity of their notes to the customer's most recent perfume search. The project utilizes a dataset containing the notes of all the perfumes carried by the retailer from its primary perfumer.",
      client: "Personal",
      technologies: "Python",
      industry: "Large Language Models",
      github: {
        name: "Perfume Recommendation Repo",
        link: "https://github.com/riji-ansari/Perfume-Recommendation-BERT",
      },
      thumbImage: "images/projects/bert.png ",
      categories: [filters.SOFTWARE],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      filter: `.${filters.DESIGN}`,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded && isotope.current) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => {
    setFilterKey(key);
    // When a new filter is applied, we need to update the isotope layout
    if (isotope.current) {
      isotope.current.arrange({ filter: key === "*" ? "*" : `.${key}` });
    }
  };

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-25 fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-9")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-10 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}

          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item"></li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Portfolio items grid */}
          <div className="portfolio popup-ajax-gallery">
            {/* ... */}

            {/* Carousel under the "Design" tab */}
            {filterKey === filters.DESIGN && (
              <div className="carousel">
                <Slider {...sliderSettings}>
                  {pdfImages.map((image, index) => (
                    <div key={index}>
                      {image.isClickable ? (
                        <a
                          href={image.linkUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={image.src} alt={`Slide ${index + 1}`} />
                        </a>
                      ) : (
                        <img src={image.src} alt={`Slide ${index + 1}`} />
                      )}
                    </div>
                  ))}
                </Slider>

                <div className="pdfButton">
                  <a
                    href="../images/Ansari-Portfolio.pdf"
                    download
                    className="btn btn-primary rounded-pill"
                  >
                    Download Portfolio
                  </a>
                </div>
              </div>
            )}
          </div>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div>
            {filterKey === filters.PHOTOGRAPHY && (
              <div>
                <ImageGallery
                  imagesInfoArray={imagesArray}
                  columnWidth={280}
                  gapSize={24}
                />
              </div>
            )}
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
